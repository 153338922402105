import { useState } from 'react';
import './App.css';
import AboutUsPage from './pages/AboutUsPage/AboutUsPage';
import HomePage from './pages/HomePage/HomePage';
import Layout from './pages/Layout/Layout';
import MenuPage from './pages/MenuPage/MenuPage';
import WorkWithUsPage from './pages/WorkWithUsPage/WorkWithUsPage';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

function App() {
    const [menuSelected, setMenuSelected] = useState<string>('Home');

    return (
        <BrowserRouter>
            <Routes>
                <Route
                    path="/"
                    element={
                        <Layout
                            children={
                                <HomePage setMenuSelected={setMenuSelected} />
                            }
                            menuSelected={menuSelected}
                            setMenuSelected={setMenuSelected}
                        />
                    }
                />
                <Route
                    path="/workwithus"
                    element={
                        <Layout
                            children={<WorkWithUsPage />}
                            menuSelected={menuSelected}
                            setMenuSelected={setMenuSelected}
                        />
                    }
                />
                <Route
                    path="/menu"
                    element={
                        <Layout
                            children={<MenuPage />}
                            menuSelected={menuSelected}
                            setMenuSelected={setMenuSelected}
                        />
                    }
                />
                <Route
                    path="/aboutus"
                    element={
                        <Layout
                            children={<AboutUsPage />}
                            menuSelected={menuSelected}
                            setMenuSelected={setMenuSelected}
                        />
                    }
                />
            </Routes>
        </BrowserRouter>
    );
}

export default App;
