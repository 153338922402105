import { Divider, Stack } from '@mui/material';
import './style.css';
import imgMap from './../../assets/mapImg.png';
import { useIsSmallScreen } from '../../hooks/useIsSmallScreen';

const ContactUsBox = (): JSX.Element => {
    const isSmallScreen = useIsSmallScreen();
    return (
        <div
            className="contact-us-box"
            style={{ padding: isSmallScreen ? '0' : '3.5rem' }}
        >
            <Stack
                direction={isSmallScreen ? 'column' : 'row'}
                spacing={'5rem'}
                width={'100%'}
                paddingLeft={isSmallScreen ? '3.5rem' : '0'}
                paddingTop={isSmallScreen ? '3.5rem' : '0'}
            >
                <Stack spacing={'3rem'}>
                    <div className="contact-us-title">Contact us</div>
                    <div className="contact-us-desc">
                        We are located in a monumental building in the heart{' '}
                        <br />
                        of the historical Centrum of Amsterdam, <br />
                        just a 2-minute walk from Rembrandtplein.
                    </div>
                    <div>
                        <Stack direction={'row'} spacing={'1rem'}>
                            <div className="contact-us-label">Street: </div>
                            <div className="contact-us-info">
                                Korte Reguliersdwarsstraat 8, 1017 <br />
                                BH Amsterdam, Netherlands
                            </div>
                        </Stack>
                        <Stack direction={'row'} spacing={'1rem'}>
                            <div className="contact-us-label">Phone:</div>
                            <div className="contact-us-info">
                                +31 20 737 2318
                            </div>
                        </Stack>
                        <Stack direction={'row'} spacing={'1rem'}>
                            <div className="contact-us-label">Email: </div>
                            <div className="contact-us-info">
                                info@demetrarestaurant.nl
                            </div>
                        </Stack>
                    </div>
                    <div className="contact-us-desc">
                        We look forward to hearing from you!
                    </div>
                </Stack>
                <Divider
                    orientation={isSmallScreen ? 'horizontal' : 'vertical'}
                    variant="middle"
                    flexItem
                    sx={{
                        borderRightWidth: 'medium',
                        marginRight: isSmallScreen ? '5rem' : '0rem',
                    }}
                />
                <Stack spacing={'3rem'}>
                    <div className="contact-us-sub-title">Opening hours</div>
                    <Stack>
                        <Stack direction={'row'} spacing={'2rem'}>
                            <div className="contact-us-opening-text day">
                                Sunday:
                            </div>
                            <div className="contact-us-opening-text">
                                5-10PM
                            </div>
                        </Stack>
                        <Stack direction={'row'} spacing={'2rem'}>
                            <div className="contact-us-opening-text day">
                                Monday:
                            </div>
                            <div className="contact-us-opening-text">
                                5-10PM
                            </div>
                        </Stack>
                        <Stack direction={'row'} spacing={'2rem'}>
                            <div className="contact-us-opening-text day">
                                Tuesday:
                            </div>
                            <div className="contact-us-opening-text">
                                Closed
                            </div>
                        </Stack>
                        <Stack direction={'row'} spacing={'2rem'}>
                            <div className="contact-us-opening-text day">
                                Wednesday:
                            </div>
                            <div className="contact-us-opening-text">
                                Closed
                            </div>
                        </Stack>
                        <Stack direction={'row'} spacing={'2rem'}>
                            <div className="contact-us-opening-text day">
                                Thursday:
                            </div>
                            <div className="contact-us-opening-text">
                                5-10PM
                            </div>
                        </Stack>
                        <Stack direction={'row'} spacing={'2rem'}>
                            <div className="contact-us-opening-text day">
                                Friday:
                            </div>
                            <div className="contact-us-opening-text">
                                5-10.30PM
                            </div>
                        </Stack>
                        <Stack direction={'row'} spacing={'2rem'}>
                            <div className="contact-us-opening-text day">
                                Saturday:
                            </div>
                            <div className="contact-us-opening-text">
                                5-10.30PM
                            </div>
                        </Stack>
                    </Stack>
                    {/* <Stack direction={'row'}>
                        <div className="contact-us-opening-text">
                            Monday Closed
                        </div>
                    </Stack> */}
                    {/* <Stack direction={'row'} spacing={'2rem'}>
                        <div className="contact-us-opening-text">
                            Sunday - Thursday
                        </div>
                        {isSmallScreen ? (
                            <Stack spacing={'1rem'}>
                                <div className="contact-us-opening-text">
                                    12am - 3pm
                                </div>
                                <div className="contact-us-opening-text">
                                    5pm - 10pm
                                </div>
                            </Stack>
                        ) : (
                            <div className="contact-us-opening-text">
                                12-3PM 5-10PM
                            </div>
                        )}
                    </Stack> */}
                    {/* <Stack direction={'row'} spacing={'2rem'}>
                        <div className="contact-us-opening-text">
                            Friday - Saturday
                        </div>
                        {isSmallScreen ? (
                            <Stack spacing={'1rem'}>
                                <div className="contact-us-opening-text">
                                    12am - 3pm
                                </div>
                                <div className="contact-us-opening-text">
                                    5pm - 10.30pm
                                </div>
                            </Stack>
                        ) : (
                            <div className="contact-us-opening-text">
                                12-3PM 5-10.30PM
                            </div>
                        )}
                    </Stack> */}
                    <a href="https://maps.app.goo.gl/aW26UBebBzEJ9pSy6">
                        <img
                            alt="map"
                            src={imgMap}
                            width={isSmallScreen ? '282px' : '424px'}
                            style={{ marginBottom: '3rem' }}
                        />
                    </a>
                </Stack>
            </Stack>
        </div>
    );
};
export default ContactUsBox;
