import { Stack } from '@mui/material';
import { useIsSmallScreen } from '../../hooks/useIsSmallScreen';
import React from 'react';

const TextPhotoBtnBox = ({
    Sx,
    Dx,
}: {
    Sx: () => JSX.Element;
    Dx: () => JSX.Element;
}) => {
    const isSmallScreen = useIsSmallScreen();
    let sxHasImg = false;
    React.Children.map(Sx(), (child) => {
        if (React.isValidElement(child) && child.type === 'img') {
            sxHasImg = true;
        }
    });
    console.log(sxHasImg);
    if (isSmallScreen && sxHasImg) {
        const temp = Sx;
        Sx = Dx;
        Dx = temp;
    }
    return (
        <Stack
            width={'100%'}
            direction={isSmallScreen ? 'column' : 'row'}
            spacing={'4rem'}
        >
            {/* {isSmallScreen ? 
                <>{sxHasImg ? <>  <Dx /> <Sx/> : <> 
                 <Sx />
                 <Dx /></>}
                </>
            : */}
            <>
                <Sx />
                <Dx />
            </>
        </Stack>
    );
};

export default TextPhotoBtnBox;
