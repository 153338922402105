import { Grid, Stack } from '@mui/material';
import CustomButton from '../../components/CustomButton/CustomButton';
import { catogory, product } from '../../types/Menu';
import './style.css';
import { Dispatch, SetStateAction } from 'react';
import { useNavigate } from 'react-router-dom';
import { useIsSmallScreen } from '../../hooks/useIsSmallScreen';

const MenuProdotto = ({ id, prod }: { id: number; prod: product }) => {
    return (
        <div key={id + 'menu_prod'}>
            <div className="menu-prodotto-title">{prod.title}</div>
            <div className="menu-prodotto-desc">{prod.desc}</div>
            {prod.extra && (
                <div className="menu-prodotto-extra">{prod.extra}</div>
            )}
        </div>
    );
};

const MenuCategoria = ({
    id,
    category,
}: {
    id: number;
    category: catogory;
}) => {
    const isSmallScreen = useIsSmallScreen();
    if(isSmallScreen && category.title === 'Pizze') {
        const halfLength = Math.ceil(category.products.length / 2);
        const products1 = category.products.slice(0, halfLength);
        const products2 = category.products.slice(halfLength);
            return <>
            <Grid key={'menu_cat_' + id} item xs={6} >
                <Stack spacing={'1.5rem'} marginTop={'5rem'}>
                    <div className="menu-categoria-title">{category.title}</div>
                    <Stack spacing={'2.5rem'}>
                        {products1.map((e, x) => (
                            <>
                                <MenuProdotto
                                    key={id + 'prod_' + x}
                                    id={x}
                                    prod={e}
                                />
                            </>
                        ))}
                    </Stack>
                </Stack>
            </Grid>
            <Grid key={'menu_cat_' + id} item xs={6} >
                <Stack spacing={'1.5rem'} marginTop={'5rem'}>
                <div className="menu-categoria-title" style={{visibility: 'hidden'}}>{category.title}</div>
                    <Stack spacing={'2.5rem'}>
                        {products2.map((e, x) => (
                            <>
                                <MenuProdotto
                                    key={id + 'prod_' + x}
                                    id={x}
                                    prod={e}
                                />
                            </>
                        ))}
                    </Stack>
                </Stack>
            </Grid></>
    }
    else return (
        <Grid key={'menu_cat_' + id} item xs={6} md={4}>
            <Stack spacing={'1.5rem'} marginTop={'5rem'}>
                <div className="menu-categoria-title">{category.title}</div>
                <Stack spacing={'2.5rem'}>
                    {category.products.map((e, x) => (
                        <>
                            <MenuProdotto
                                key={id + 'prod_' + x}
                                id={x}
                                prod={e}
                            />
                        </>
                    ))}
                </Stack>
            </Stack>
        </Grid>
    )
};

const MenuPage = ({
    homepage = false,
    setMenuSelected,
}: {
    homepage?: boolean;
    setMenuSelected?: Dispatch<SetStateAction<string>>;
}): JSX.Element => {
    const navigate = useNavigate();
    const categorieHomepage = ['Antipasti', 'Primi', 'Pizze'];
    const isSmallScreen = useIsSmallScreen();
    const categorie: catogory[] = [
        {
            title: 'Antipasti',
            products: [
                {
                    title: 'BRUSCHETTA AL POMODORO',
                    desc: 'Homemade toasted bread, basil-marinated tomatoes, oregano, garlic, evoo',
                },
                {
                    title: 'BURRATINA, VERDURE ARROSTO, MIELE PICCANTE E TARALLO',
                    desc: 'Burrata, roasted veggies, spicy honey, crumbled tarallo',
                },
                {
                    title: 'TAGLIERE DI SALUMI E FORMAGGI (serves 2 ppl)',
                    desc: 'Charcuterie board, homemade bread, marinated olives, homemade compote of the day',
                },
                {
                    title: 'CARPACCIO DI BRESAOLA AL TARTUFO (serves 2)',
                    desc: 'Italian beef Bresaola carpaccio, fresh truffle, shaved Parmigiano, arugula, balsamic glaze, evoo',
                },
                {
                    title: 'GAMBERI ARROSTO, CANNELLINI, STRACCIATELLA E CIPOLLA AGRODOLCE',
                    desc: 'Roasted prawn, cannellini beans purée, stracciatella, caramelized sweet & sour red onions',
                    extra: (
                        <>
                            <b>Chef’s suggestion:</b> add fresh black truffle
                        </>
                    ),
                },
                {
                    title: 'PANCIA DI MAIALE, CAVOLFIORE, PERA E FUMO AL ROSMARINO',
                    desc: 'Slow-braised pork belly, smoked cauliflower puree, marinated pear, rosemary smoke',
                },
                {
                    title: 'INSALATINA DEMETRA',
                    desc: 'Greens, cherry tomatoes, strawberries, croutons, shaved Parmigiano, balsamic glaze, crumbled pistachios, evoo',
                },
            ],
        },
        {
            title: 'Pasta',
            products: [
                {
                    title: 'GRANO ARSO, POMODORO PICCANTE E BURRATA',
                    desc: 'Burnt-wheat Tagliolini, spicy yellow tomato sauce, tomato powder, basil, whole burrata',
                },
                {
                    title: 'STRACOTTO, TARALLO E PROVOLONE DEL MONACO',
                    desc: 'Pappardelle, slowly-braised beef ragout, crumbled tarallo, Provolone del Monaco cream',
                },
                {
                    title: 'GNOCCHI RIPIENI',
                    desc: 'Gnocchi stuffed with Taleggio on pumpkin puree, toasted hazelnut, shaved fresh truffle',
                },
                {
                    title: 'RAVIOLI PATATE E PORRI CON PARMIGIANO, TUORLO E PANCETTA',
                    desc: 'Ravioli stuffed with potatoes and leeks, parmigiano cream, cured egg yolk, crumbled crispy bacon',
                    extra: (
                        <>
                            <b>Chef’s suggestion:</b> add fresh black truffle
                        </>
                    ),
                },
                {
                    title: 'RISOTTO FUNGHI TARTUFO E PARMIGIANO CROCCANTE',
                    desc: 'Acquerello rice, mixed mushrooms, fresh truffle and parmigiano crisps',
                },
                {
                    title: 'RISOTTO GAMBERI, AGLIO NERO, SCORZA DI LIMONE E STRACCIATELLA',
                    desc: 'Acquerello rice, bisque, prawn tartare, black garlic, stracciatella and lemon zest',
                },
            ],
        },
        {
            title: 'Pizze',
            products: [
                {
                    title: 'MARGHERITA',
                    desc: 'Tomato San Marzano BIO, Fiordilatte D’Agerola, basil, evoo',
                },
                {
                    title: 'BUFALINA',
                    desc: 'Tomato San Marzano BIO, imported Buffalo Mozzarella, basil, evoo',
                },
                {
                    title: 'SALAMINO PICCANTE',
                    desc: 'Tomato San Marzano BIO, Fiordilatte D’Agerola, spicy salamino, basil, evoo',
                },
                {
                    title: 'CRUDO',
                    desc: 'Fiordilatte D’Agerola, Prosciutto crudo, shaved Parmigiano, arugula, semi-dry cherry tomatoes, basil, evoo',
                },
                {
                    title: 'BURRAPIZZA',
                    desc: 'Yellow cherry tomato sauce, whole burrata, semi-dry cherry tomatoes, Calabrian nduja, peperone crusco',
                },
                {
                    title: 'MONACHELLA',
                    desc: 'Pumpkin, mushroom, provolone del Monaco, crumbled tarallo',
                },
                {
                    title: 'CORNICIONE RIPIENO',
                    desc: 'Basil-ricotta, Fiordilatte d’Agerola, pesto, ham, semi-dry cherry tomatoes',
                },
                {
                    title: 'MORTAZZA',
                    desc: 'Fiordilatte d’Agerola, mortadella, stracciatella, pistachio, basil, evoo',
                },
                {
                    title: 'NORCINA',
                    desc: 'Buffalo Mozzarella, Italian sausage, mushroom, caramelized onion, basil, evoo',
                },
                {
                    title: 'STRACCIATELLA E ALICI',
                    desc: 'Tomato San Marzano BIO, stracciatella, anchovies, black garlic, semidry cherry tomatoes, lemon zest',
                },
                {
                    title: 'TONNO, POLVERE DI OLIVE E CIPOLLE',
                    desc: 'Italian tuna, yellow cherry tomato sauce, semi-dry tomatoes, crispy onion, black olive powder',
                },
                {
                    title: 'SALSICCIA, FRIARIELLI E PROVOLA',
                    desc: 'Fiordilatte d’Agerola, broccoli rabe, Italian sausage',
                },
            ],
        },
    ];

    // const onHandleClick = () => {
    //     if (setMenuSelected !== undefined) setMenuSelected('Menu');
    //     navigate('/menu');
    // };

    return (
        <div>
            <div className="menu-title">Our Italian taste in every bite</div>
            <Stack>
                <div className="menu-page-label-sub">
                    {/* Discover the entire menu, download it from the button below. */}
                    Come back soon for our full menu
                </div>
            </Stack>
            <Stack alignItems={isSmallScreen ? 'flex-end':'center'} marginTop={'2.5rem'}>
                <CustomButton text="Download menu" enabled={false} />
            </Stack>
            {/* <Grid container spacing={2}>
                {categorie.map((e, x) => {
                    return (
                        <MenuCategoria key={'cat_' + x} id={x} category={e} />
                    );
                })}
            </Grid> */}
        </div>
    );
};

export default MenuPage;
