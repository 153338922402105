import { Button, ButtonProps } from '@mui/material';
import { styled } from '@mui/material/styles';
import { CSSProperties } from 'react';

interface ColorButtonProps extends ButtonProps {
    enabled: string;
    sx?: CSSProperties;
}

const ColorButton = styled(Button)<ColorButtonProps>(({ enabled,sx }) => ({
    backgroundColor: enabled === 'true' ? 'rgba(103, 114, 70, 1)' : '#b6c288',
    '&:hover': {
        backgroundColor:
            enabled === 'true' ? 'rgba(103, 114, 70, 1)' : '#b6c288',
    },
    fontFamily: 'Raleway',
    borderRadius: '100px',
    minWidth: '11rem',
    color: '#FFF',
    textTransform: 'none',
    fontSize: '1rem',
    maxWidth: '200px',
    height: '54px',
    ...sx
}));

const CustomButton = ({
    text,
    enabled,
    onClick,
    sx,
}: {
    text: string;
    enabled: boolean;
    onClick?: (e: any) => void;
    sx?: CSSProperties;
}) => {
    return (
        <ColorButton
            className="btn-custom"
            onClick={enabled ? onClick : undefined}
            enabled={enabled ? 'true' : 'false'}
            sx={sx}
        >
            {' '}
            {text}{' '}
        </ColorButton>
    );
};

export default CustomButton;
