import { Stack } from '@mui/material';
import TextPhotoBtnBox from '../../components/TextPhotoBtnBox/TextPhotoBtnBox';
import CustomButton from '../../components/CustomButton/CustomButton';
import { useNavigate } from 'react-router-dom';
import { Dispatch, SetStateAction } from 'react';
import pic1 from './../../assets/pictures/picture1.png';
import pic2 from './../../assets/images/GFrigieri_DEMETRA-day2-225.jpg'
import pic3 from './../../assets/pictures/picture3.png';
import TextBtnBox from '../../components/TextBtnBox/TextBtnBox';
import MenuPage from '../MenuPage/MenuPage';
import ContactUsBox from '../../components/ContactUsBox/ContactUsBox';
import Reviews from '../../components/Reviews/Reviews';
import backgroundMenu from './../../assets/background-menu.svg';
import { useIsSmallScreen } from '../../hooks/useIsSmallScreen';

const HomePage = ({
    setMenuSelected,
}: {
    setMenuSelected: Dispatch<SetStateAction<string>>;
}): JSX.Element => {
    const navigate = useNavigate();
    const isSmallScreen = useIsSmallScreen();

    const onHandleClick = () => {
        setMenuSelected('About us');
        navigate('/aboutus');
    };
    const BtnAboutUs = () => (
        <CustomButton text="About us" enabled={true} onClick={onHandleClick} />
    );

    const BtnExplore = () => <CustomButton text="Explore" enabled={true} />;

    const data = [
        {
            title: 'A modern tribute to Italian tradition',
            desc: [
                <><b>Welcome to Demetra</b>, where <b>passion</b> for traditional Italian cuisine <b>meets the art of modern culinary innovation.</b><br/> We take pride in bringing the real Italian flavors to your table, with a fun and innovative twist.<br/> Whether you are craving some mouthwatering pasta, <b>unforgettable pizzas, or flavorful risottos</b>, you’ve come to the right place!</>,
                <>And if you are in the mood for a decadent dessert, say no more! <b>Take a seat and enjoy our menu</b>, a playful exploration of flavors, textures, and aromas that will transport you straight to the heart of Italy!</>
            ],
            btn: BtnAboutUs,
            img: <img alt="pic1" src={pic1} />,
        },
        {
            title: 'Always from scratch',
            desc: [
               <>At Demetra, our motto is <b>‘always from scratch’.</b> We believe in offering a <b>real Italian cuisine experience</b> using all the best quality ingredients.<br/> All of our fresh pasta, bread, pizza dough, sauces and desserts are <b>made in-house from scratch</b>, utilizing the finest imported Italian flour, cheese, and cold cuts, as well as high-quality locally sourced produce and meat.</>,
               <><b>Our artisanal pizza is crafted using the 'biga' method</b>, an Italian <b>pre-fermentation technique</b> that involves a 48-hour proofing process.<br/> This method <b>enhances flavors and ensures optimal digestibility.</b></>
            ],
            btn: BtnAboutUs,
            img: <img alt="pic2" src={pic2} style={isSmallScreen ? {width: '100%'} : {width: '480px', height: '670px'}} />,
        },
        {
            title: "Our water cares",
            desc: [
               <>As of today, <b>771 million people do not have access to clean and safe water.</b><br/> We are proud to have partnered with Made Blue Foundation, an organization committed to raising funds for investing in access to clean water and hygiene all over the world.<br/> <b>Each Made Blue Foundation bottle provides a thousand times its content of water in developing countries.</b></>,
            ],
            btn: BtnExplore,
            img: <img alt="pic3" src={pic3} />,
        },
    ];
    return (
        <>
            <Stack
                alignItems={'center'}
                spacing={'5rem'}
                marginTop={'5rem'}
                marginBottom={'5rem'}
            >
                <TextPhotoBtnBox
                    key={0}
                    Sx={() => (
                        <TextBtnBox
                            title={data[0].title}
                            desc={data[0].desc}
                            Btn={data[0].btn}
                        />
                    )}
                    Dx={() => data[0].img}
                />
                <TextPhotoBtnBox
                    key={1}
                    Sx={() => data[1].img}
                    Dx={() => (
                        <TextBtnBox
                            title={data[1].title}
                            desc={data[1].desc}
                            Btn={data[1].btn}
                        />
                    )}
                />
                {/* <div
                    style={{
                        marginLeft: !isSmallScreen ? '-10rem' : 0,
                        marginRight: !isSmallScreen ? '-10rem' : 0,
                        padding: !isSmallScreen ? '5rem 10rem 5rem 10rem' : 0,
                        backgroundImage: 'url(' + backgroundMenu + ')',
                    }}
                >
                    <MenuPage homepage setMenuSelected={setMenuSelected} />
                </div> */}
                <TextPhotoBtnBox
                    key={1}
                    Sx={() => (
                        <TextBtnBox
                            title={data[2].title}
                            desc={data[2].desc}
                            Btn={data[2].btn}
                        />
                    )}
                    Dx={() => data[2].img}
                />
            </Stack>
            {/* <Reviews /> */}
            <Stack alignItems={'center'} spacing={'5rem'} marginTop={'5rem'}>
                <ContactUsBox />
            </Stack>
        </>
    );
};

export default HomePage;
