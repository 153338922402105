import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import translationEN from './locales/en.json';
import translationIT from './locales/it.json';

const resources = {
    en: {
        translation: translationEN,
    },
    it: {
        translation: translationIT,
    },
};

i18n.use(initReactI18next).init({
    resources,
    lng: 'en', // Lingua predefinita
    fallbackLng: 'en', // Lingua di fallback nel caso in cui quella selezionata non sia disponibile
    interpolation: {
        escapeValue: false, // Non fare l'escape dei valori HTML nei testi tradotti
    },
});

export default i18n;
