/* eslint-disable */
import { Stack, TextField } from '@mui/material';
import { useState } from 'react';
import img from '../../assets/images/wwu.png';
import { useIsSmallScreen } from '../../hooks/useIsSmallScreen';
import CustomButton from '../CustomButton/CustomButton';
import './style.css';

const WorkWithUsBox = ({
    status,
    message,
    onValidated,
}: {
    status: string | null;
    message?: string | Error | null;
    onValidated: (formData: any) => void;
}): JSX.Element => {
    const isSmallScreen = useIsSmallScreen();
    const [email, setEmail] = useState<string>('');
    const [name, setName] = useState<string>('');
    const [cover, setCover] = useState<string>('');
    const [role, setRole] = useState<string>('');

    const [, setNotValid] = useState<string>('');
    const [btnEnabled, setBtnEnabled] = useState<boolean>(false);
    const handleSubmit = (e: any) => {
        e.preventDefault();
        if (!email) setNotValid('Email empty');
        else if (email.indexOf('@') > -1) setNotValid('is not an email valid');
        else setNotValid('');
        // email && email.indexOf("@") > -1 &&
        onValidated({
            EMAIL: email,
            MERGE1: name,
            MERGE2: role,
            MERGE3: cover,
        });
    };

    const onHandleChangeEmail = (e: any) => {
        if (e.target.value.length > 2) {
            setBtnEnabled(true);
        } else {
            setBtnEnabled(false);
        }
        setEmail(e.target.value);
    };

    const onHandleChangeName = (e: any) => {
        setName(e.target.value);
    };

    const onHandleChangeCover = (e: any) => {
        setCover(e.target.value);
    };

    const onHandleChangeRole = (e: any) => {
        setRole(e.target.value);
    };

    return (
        <div className="box-work">
            <Stack
                spacing={'2rem'}
                sx={{
                    width: isSmallScreen ? '100%' : '50%',
                    padding: isSmallScreen
                        ? '2.5rem 2.5rem 1rem 2.5rem'
                        : '2.5rem 0 1rem 2.5rem',
                }}
            >
                <div className="text-title">Join Our Team</div>
                <div className="text-desc">
                    Are you passionate about Italian cuisine and <br />{' '}
                    dedicated to delivering exceptional service?
                    <br />
                    <br />
                    If so, we want to hear from you!
                </div>
                <Stack
                    direction={isSmallScreen ? 'column' : 'row'}
                    spacing={'2rem'}
                >
                    <TextField
                        variant="outlined"
                        fullWidth
                        label="Name"
                        placeholder="Insert Name"
                        onChange={onHandleChangeName}
                    />
                    <TextField
                        variant="outlined"
                        fullWidth
                        label="Role"
                        placeholder="Insert Role"
                        onChange={onHandleChangeRole}
                    />
                </Stack>
                <TextField
                    variant="outlined"
                    fullWidth
                    label="Email"
                    placeholder="Your email"
                    onChange={onHandleChangeEmail}
                />
                <TextField
                    variant="outlined"
                    fullWidth
                    label="Cover Letter"
                    placeholder="Insert letter"
                    onChange={onHandleChangeCover}
                />
                {/* <TextField
                variant="outlined"
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <UploadFileIcon />
                        </InputAdornment>
                    ),
                }}
                fullWidth
                label="CV"
                placeholder="Attached CV"
            /> */}
                <CustomButton
                    text="Send application"
                    enabled={btnEnabled}
                    onClick={handleSubmit}
                    sx={{ alignSelf: 'flex-end', minWidth: '184px' }}
                />
                <div
                    style={{
                        marginTop:
                            status != null
                                ? '1rem'
                                : isSmallScreen
                                ? '0.5rem'
                                : '1rem',
                        marginBottom: status != null ? '1rem' : '',
                    }}
                >
                    {status === 'sending' && (
                        <div className="sign-up-desc">sending...</div>
                    )}
                    {status === 'error' && (
                        <div className="sign-up-desc error">
                            {message?.toString()}
                        </div>
                    )}
                    {status === 'success' && (
                        <div className="sign-up-desc">
                            {message?.toString()}
                        </div>
                    )}
                </div>
            </Stack>
            {isSmallScreen ? null : (
                <img className="img-work" alt="work" src={img} />
            )}
        </div>
    );
};

export default WorkWithUsBox;
