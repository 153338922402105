import { Stack } from '@mui/material';
import './style.css';

const TextBtnBox = ({
    title,
    desc,
    Btn,
}: {
    title: string;
    desc: string[] | JSX.Element[];
    Btn?: () => JSX.Element;
}) => {
    return (
        <Stack>
            <div className="text-box-title">{title}</div>
            {desc.map((e, x) => (
                <div key={x} className="text-box-desc">
                    {e}
                </div>
            ))}
            <div style={{ marginTop: '1.5rem' }}>
                {Btn && <Btn/>}
            </div>
        </Stack>
    );
};

export default TextBtnBox;
