import MailchimpSubscribe, { EmailFormFields } from 'react-mailchimp-subscribe';
import WorkWithUsBox from './WorkWithUsBox';

const MailchimpFormWorkContainer = () => {
    const postUrl = `https://demetrarestaurant.us21.list-manage.com/subscribe/post?u=bc86d3229c2952a84f43cb1c6&id=b834920b99`;

    return (
        <div className="mc__form-container">
            <MailchimpSubscribe
                url={postUrl}
                render={({ subscribe, status, message }) => (
                    <WorkWithUsBox
                        status={status}
                        message={message}
                        onValidated={(formData: EmailFormFields) => {
                            subscribe(formData);
                        }}
                    />
                )}
            />
        </div>
    );
};

export default MailchimpFormWorkContainer;
