import { Grid, Stack } from '@mui/material';
import { useIsSmallScreen } from '../../hooks/useIsSmallScreen';
import imgtop from './../../assets/images/GFrigieri_DEMETRA-day2-11.jpg';
import imgtopMobile from './../../assets/images/demetra-wine.jpg';
import img3 from './../../assets/images/img1.png';
import img5 from './../../assets/images/img2.png';
import img4 from './../../assets/images/img3.png';
import img2 from './../../assets/images/img4.png';
import img1 from './../../assets/images/img5.png';
import img6 from './../../assets/images/img6.png';

import './style.css';

const AboutUsPage = (): JSX.Element => {
    const isSmallScreen = useIsSmallScreen();
    return (
        <>
            <Stack alignItems={'center'} spacing={'5rem'}>
                <Stack
                    direction={isSmallScreen ? 'column' : 'row'}
                    spacing={'5rem'}
                >
                    <Stack justifyContent={'center'}>
                        <div className="title-about-us">
                            Much more than just a restaurant
                        </div>
                        <div className="desc-title-about-us">
                            Named in{' '}
                            <b>honor of the goddess of grain, Demetra,</b> our
                            restaurant is a haven for those who appreciate the
                            timeless traditions of{' '}
                            <b>
                                homemade fresh pasta and the soul-warming
                                delights of perfectly crafted pizzas.
                            </b>
                            But there's a whole lot more to our restaurant than
                            just serving up delicious dishes.{' '}
                            <b>
                                We have envisioned our restaurant as an Italian
                                ‘cucina’
                            </b>
                            , where everything is prepared fresh to the order
                            for our patrons.
                        </div>
                    </Stack>
                    <img
                        alt="aboutus"
                        src={isSmallScreen ? imgtopMobile : imgtop}
                        style={
                            isSmallScreen
                                ? {width: '100%', height: '100%'}
                                : { width: '520px', height: '590px' }
                        }
                    />
                </Stack>
                <div className="nd-desc-about-us">
                    Nothing gets us more excited - and hungry! - for dinner than
                    seeing a pizzaiolo in action, expertly stretching dough and
                    topping it with the finest ingredients before sliding it
                    into the oven. We hope you’ll feel the same!
                </div>
            </Stack>
            <div style={{ marginTop: '7.5rem', marginBottom: '7.5rem' }}>
                {/* <ImagesCarousel /> */}
                <Grid container spacing={'1.5rem'}>
                    {isSmallScreen ? (
                        <>
                            <Grid item xs={6}>
                                <img
                                    alt="img1"
                                    src={img1}
                                    width={'158px'}
                                    height={'158px'}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <img
                                    alt="img2"
                                    src={img2}
                                    width={'158px'}
                                    height={'158px'}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <img
                                    alt="img3"
                                    src={img3}
                                    width={'158px'}
                                    height={'158px'}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <img
                                    alt="img4"
                                    src={img4}
                                    width={'158px'}
                                    height={'158px'}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <img
                                    alt="img5"
                                    src={img5}
                                    width={'158px'}
                                    height={'158px'}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <img
                                    alt="img6"
                                    src={img6}
                                    width={'158px'}
                                    height={'158px'}
                                />
                            </Grid>
                        </>
                    ) : (
                        <>
                            <Grid item xs={4}>
                                <img
                                    alt="img1"
                                    src={img1}
                                    width={'357px'}
                                    height={'357px'}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <img
                                    alt="img2"
                                    src={img2}
                                    width={'357px'}
                                    height={'357px'}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <img
                                    alt="img3"
                                    src={img3}
                                    width={'357px'}
                                    height={'357px'}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <img
                                    alt="img4"
                                    src={img4}
                                    width={'357px'}
                                    height={'357px'}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <img
                                    alt="img5"
                                    src={img5}
                                    width={'357px'}
                                    height={'357px'}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <img
                                    alt="img6"
                                    src={img6}
                                    width={'357px'}
                                    height={'357px'}
                                />
                            </Grid>
                        </>
                    )}
                </Grid>
            </div>
            {isSmallScreen ? (
                <Stack spacing="40px">
                    <div className="about-us-title-3">Our Story</div>
                    <div className="about-us-desc-3">
                        Our adventure begins with a dream: to bring all the
                        authentic taste, freshness and love of a real Italian
                        ‘cucina’ to your table. We are dedicated to creating a
                        dining experience that willresonate with both your
                        palate and your soul,revealing how passionate we are
                        about ourfood. With every dish we lovingly prepare, our
                        goal is to bring back the unforgettable flavorsof a trip
                        to Italy, making each bite a nostalgic journey you'll
                        cherish. We have carefully designed our menu with this
                        mission in mind. So, welcome to our kitchen, we hope
                        every visit to Demetra will transport you to the warm
                        and fun atmosphere of an Italian home.
                    </div>
                </Stack>
            ) : (
                <Grid container spacing={'5rem'}>
                    <Grid
                        item
                        xs={12}
                        md={6}
                        lg={6}
                        sx={{ display: 'flex', alignItems: 'flex-end' }}
                    >
                        <Stack spacing="40px">
                            <div className="about-us-title-3">Our Story</div>
                            <div className="about-us-desc-3">
                                Our adventure begins with a dream: to bring all
                                the authentic taste, freshness and love of a
                                real Italian ‘cucina’ to your table. We are
                                dedicated to creating a dining experience that
                                willresonate with both your palate and your
                                soul,
                            </div>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                        <div className="about-us-desc-4">
                            revealing how passionate we are about ourfood. With
                            every dish we lovingly prepare, our goal is to bring
                            back the unforgettable flavorsof a trip to Italy,
                            making each bite a nostalgic journey you'll cherish.
                            We have carefully designed our menu with this
                            mission in mind. So, welcome to our kitchen, we hope
                            every visit to Demetra will transport you to the
                            warm and fun atmosphere of an Italian home.
                        </div>
                    </Grid>
                </Grid>
            )}
        </>
    );
};

export default AboutUsPage;
