import { Stack } from '@mui/material';
import footerLogo from '../../assets/logo-footer.png';
import iconSocial1 from '../../assets/icon-social1.png';
import iconSocial2 from '../../assets/icon-social2.png';
import './style.css';
import { useIsSmallScreen } from '../../hooks/useIsSmallScreen';
import { pageProps } from '../../interfaces/props';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Footer = ({ menuSelected, setMenuSelected }: pageProps) => {
    const isSmallScreen = useIsSmallScreen();
    const { t } = useTranslation();
    const handleItemClick = (itemName: string) => {
        setMenuSelected(itemName);
    };
    return (
        <>
            {isSmallScreen ? (
                <Stack className="footer-box" spacing={'1rem'}>
                    <Stack direction={'row'} justifyContent={'space-between'}>
                        <img
                            alt="footer-logo"
                            src={footerLogo}
                            width={'120px'}
                            height={'120px'}
                        />
                        <Stack spacing={'1rem'} direction={'row'}>
                            <a href='https://www.instagram.com/demetra.amsterdam/'>
                                <img
                                    alt="icon-social-1"
                                    src={iconSocial1}
                                    width={'40px'}
                                    height={'40px'}
                                />
                            </a>
                            {/* <img
                                alt="icon-social-2"
                                src={iconSocial2}
                                width={'40px'}
                                height={'40px'}
                            /> */}
                        </Stack>
                    </Stack>

                    <div className="footer-desc">
                        We are much more than just a restaurant;
                        <br /> we are a culinary family with deep roots in{' '}
                        <br /> Italian tradition and a passion for creating{' '}
                        <br /> unforgettable gastronomic experiences.
                    </div>
                    {/* <Stack>
                <Stack direction={'row'}>
                    <div>Home</div>
                    <div>About Us</div>
                </Stack>
                <Stack direction={'row'}>
                    <div>Menu</div>
                    <div>Work with us</div>
                </Stack>
            </Stack> */}
                </Stack>
            ) : (
                <Stack className="footer-box">
                    <img
                        alt="footer-logo"
                        src={footerLogo}
                        width={'120px'}
                        height={'120px'}
                    />
                    <Stack
                        direction={'row'}
                        className="footer-body"
                        justifyContent={'space-between'}
                    >
                        <div className="footer-desc">
                            We are much more than just a restaurant; we <br />{' '}
                            are a culinary family with deep roots in Italian{' '}
                            <br /> tradition and a passion for creating <br />{' '}
                            unforgettable gastronomic experiences.
                        </div>
                        <Stack direction={'row'} spacing={'5rem'}>
                            <Stack spacing={'2rem'}>
                                <Link
                                    to={'/'}
                                    className={
                                        'item-menu-footer ' +
                                        (menuSelected === 'Home'
                                            ? 'selected'
                                            : '')
                                    }
                                    onClick={() => handleItemClick('Home')}
                                >
                                   {t('home')}
                                </Link>
                                <Link
                                    to={'/menu'}
                                    className={
                                        'item-menu-footer ' +
                                        (menuSelected === 'Menu'
                                            ? 'selected'
                                            : '')
                                    }
                                    onClick={() => handleItemClick('Menu')}
                                >
                                    {t('menu')}
                                </Link>
                            </Stack>
                            <Stack spacing={'2rem'}>
                                <Link
                                    to={'/aboutus'}
                                    className={
                                        'item-menu-footer ' +
                                        (menuSelected === 'About us'
                                            ? 'selected'
                                            : '')
                                    }
                                    onClick={() => handleItemClick('About us')}
                                >
                                    {t('aboutUs')}
                                </Link>
                                <Link
                                    to={'/workwithus'}
                                    className={
                                        'item-menu-footer ' +
                                        (menuSelected === 'Work with us'
                                            ? 'selected'
                                            : '')
                                    }
                                    onClick={() =>
                                        handleItemClick('Work with us')
                                    }
                                >
                                    {t('workWithUs')}
                                </Link>
                            </Stack>
                        </Stack>
                        <Stack spacing={'1rem'}>
                            <div className="social-text">Social</div>
                            <Stack spacing={'1rem'} direction={'row'}>
                             <a href='https://www.instagram.com/demetra.amsterdam/'>
                                <img
                                    alt="icon-social-1"
                                    src={iconSocial1}
                                    width={'40px'}
                                    height={'40px'}
                                />
                             </a>
                                {/* <img
                                    alt="icon-social-2"
                                    src={iconSocial2}
                                    width={'40px'}
                                    height={'40px'}
                                /> */}
                            </Stack>
                        </Stack>
                    </Stack>
                </Stack>
            )}
        </>
    );
};

export default Footer;
