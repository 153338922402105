import { Stack } from '@mui/material';
import { Link } from 'react-router-dom';
import { pageProps } from '../../interfaces/props';
import './style.css';
import { useTranslation } from 'react-i18next';

const NavBar = ({ menuSelected, setMenuSelected, isHome }: pageProps) => {
    const handleItemClick = (itemName: string) => {
        setMenuSelected(itemName);
    };
    const { t } = useTranslation();
    return (
        <Stack
            direction={'row'}
            spacing={'7.5rem'}
            justifyContent={'space-between'}
        >
            <Link
                to="/"
                className={
                    'menu-item ' +
                    (menuSelected === 'Home' ? 'selected ' : '') +
                    (isHome ? 'home' : '')
                }
                onClick={() => handleItemClick('Home')}
            >
                {t('home')}
            </Link>
            <Link
                to="/aboutus"
                className={
                    'menu-item ' +
                    (menuSelected === 'About us' ? 'selected ' : '') +
                    (isHome ? 'home' : '')
                }
                onClick={() => handleItemClick('About us')}
            >
                {t('aboutUs')}
            </Link>
            <Link
                to="/menu"
                className={
                    'menu-item ' +
                    (menuSelected === 'Menu' ? 'selected ' : '') +
                    (isHome ? 'home' : '')
                }
                onClick={() => handleItemClick('Menu')}
            >
                {t('menu')}
            </Link>
            <Link
                to="/workwithus"
                className={
                    'menu-item ' +
                    (menuSelected === 'Work with us' ? 'selected ' : '') +
                    (isHome ? 'home' : '')
                }
                onClick={() => handleItemClick('Work with us')}
            >
                {t('workWithUs')}
            </Link>
        </Stack>
    );
};

export default NavBar;
