import MenuIcon from '@mui/icons-material/Menu';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import * as React from 'react';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import logoWhite from './../../assets/logo-white.svg';
import CloseIcon from '@mui/icons-material/Close';
import { Stack } from '@mui/material';
import './style.css';
export const NavBarMobile = () => {
    const [state, setState] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();

    const onHandleClick = (textBtn: string) => {
        let to = '';
        switch (textBtn) {
            case 'Home':
                to = '/';
                break;
            case 'About us':
                to = '/aboutus';
                break;
            case 'Menu':
                to = '/menu';
                break;
            case 'Work with us':
                to = '/workwithus';
                break;
        }
        navigate(to);
    };

    const toggleDrawer =
        (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
            if (
                event &&
                event.type === 'keydown' &&
                ((event as React.KeyboardEvent).key === 'Tab' ||
                    (event as React.KeyboardEvent).key === 'Shift')
            ) {
                return;
            }

            setState(open);
        };

    const ListItems = () => (
        <Box
            role="presentation"
            onClick={toggleDrawer(false)}
            onKeyDown={toggleDrawer(false)}
            width={'80%'}
            height={'100vh'}
        >
            <Stack
                direction={'row'}
                marginTop={'1rem'}
                justifyContent={'center'}
                alignItems={'center'}
            >
                <img src={logoWhite} alt="logo" />
                <CloseIcon
                    sx={{
                        color: 'white',
                        position: 'absolute',
                        right: '1.5rem',
                    }}
                    fontSize="large"
                />
            </Stack>
            <Stack marginTop={'5rem'} alignItems={'center'}>
                <List>
                    {['Home', 'About us', 'Menu', 'Work with us'].map(
                        (text, index) => (
                            <ListItem
                                onClick={() => onHandleClick(text)}
                                key={index}
                                disablePadding
                            >
                                <ListItemButton
                                    sx={{ justifyContent: 'center' }}
                                >
                                    <div className="item-nav-bar-mobile">
                                        {text}
                                    </div>
                                </ListItemButton>
                            </ListItem>
                        )
                    )}
                </List>
            </Stack>
        </Box>
    );

    return (
        <div>
            <>
                <MenuIcon
                    fontSize="large"
                    sx={{
                        color: location.pathname === '/' ? 'white' : 'black',
                    }}
                    onClick={toggleDrawer(true)}
                />
                <SwipeableDrawer
                    anchor={'left'}
                    open={state}
                    onClose={toggleDrawer(false)}
                    onOpen={toggleDrawer(true)}
                    PaperProps={{
                        sx: {
                            width: '100%',
                            backgroundColor: 'rgba(103, 114, 70, 1)',
                            alignItems: 'center',
                        },
                    }}
                >
                    <ListItems />
                </SwipeableDrawer>
            </>
        </div>
    );
};

export default NavBarMobile;
