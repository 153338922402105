import { Stack } from '@mui/material';
import { CSSProperties, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Footer from '../../components/Footer/Footer';
import NavBar from '../../components/NavBar/NavBar';
import { NavBarMobile } from '../../components/NavBarMobile/NavBarMobile';
import { useIsSmallScreen } from '../../hooks/useIsSmallScreen';
import { layoutProps } from '../../interfaces/props';
import backgroundMenu from './../../assets/background-menu.svg';
import backgroundHome from './../../assets/images/GFrigieri_DEMETRA-day2-19.jpg';
import backgroundHomeMobile from './../../assets/images/background-homepage-mobile.png';
import logoVerde from './../../assets/logo-verde.png';
import logoWhite from './../../assets/logo-white.svg';
import logo_mobile from './../../assets/LogoMobileGrande.svg';
import title_logo from './../../assets/Web.svg';
import './style.css';

const Layout = ({ menuSelected, setMenuSelected, children }: layoutProps) => {
    const isSmallScreen = useIsSmallScreen();
    const navigate = useNavigate();
    const [isMenu, setIsMenu] = useState(false);
    const [isHome, setIsHome] = useState(false);
    const location = useLocation();
    const styleBackMenu: CSSProperties = {
        backgroundImage: 'url(' + backgroundMenu + ')',
        paddingBottom: '5rem',
    };
    const styleBackHome: CSSProperties = {
        backgroundImage:
            'url(' +
            (isSmallScreen ? backgroundHomeMobile : backgroundHome) +
            ')',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
    };

    useEffect(() => {
        if (location.pathname.includes('menu')) setIsMenu(true);
        else setIsMenu(false);
        if (location.pathname === '/') setIsHome(true);
        else setIsHome(false);

        window.scrollTo(0, 0);
    }, [location.pathname]);

    const onHandleClickLogo = () => navigate('/');

    return (
        <div style={isHome ? styleBackHome : {}}>
            <div className={isSmallScreen ? 'div-menu-mobile' : 'div-menu'}>
                {isSmallScreen ? (
                    <>
                        <div className="div-nav-bar-mobile">
                            <NavBarMobile />
                        </div>
                        <Stack
                            direction={'row'}
                            alignItems="center"
                            justifyContent={'center'}
                        >
                            <img
                                alt="logo"
                                width={'56px'}
                                height={'56px'}
                                src={isHome ? logoWhite : logoVerde}
                                style={{ cursor: 'pointer' }}
                                onClick={onHandleClickLogo}
                            />
                        </Stack>
                    </>
                ) : (
                    <Stack
                        direction={'row'}
                        alignItems="center"
                        justifyContent={'space-between'}
                    >
                        <img
                            alt="logo"
                            width={'56px'}
                            height={'56px'}
                            src={isHome ? logoWhite : logoVerde}
                            style={{ cursor: 'pointer' }}
                            onClick={onHandleClickLogo}
                        />
                        <NavBar
                            menuSelected={menuSelected}
                            setMenuSelected={setMenuSelected}
                            isHome={isHome}
                        />
                    </Stack>
                )}
            </div>
            {isHome ? (
                <Stack
                    height={'100vh'}
                    alignItems={'center'}
                    justifyContent={'center'}
                >
                    {/* <div
                        style={{width: '235px',
                        height:'235px'
                        }}
                        // src={!isSmallScreen ? title_logo : logo_mobile}
                    /> */}
                </Stack>
            ) : (
                ''
            )}
            <div style={isMenu ? styleBackMenu : {}}>
                <div
                    className={
                        isSmallScreen ? 'web-container-mobile' : 'web-container'
                    }
                >
                    {children}
                </div>
            </div>
            <div className="div-footer">
                <Footer
                    menuSelected={menuSelected}
                    setMenuSelected={setMenuSelected}
                />
            </div>
        </div>
    );
};

export default Layout;
