import { Stack } from '@mui/material';
import './style.css';

import icon1 from '../../assets/work-with-us/icon1.png';
import icon2 from '../../assets/work-with-us/icon2.png';
import icon3 from '../../assets/work-with-us/icon3.png';
import icon4 from '../../assets/work-with-us/icon4.png';
import MailchimpFormWorkContainer from '../../components/WorkWithUsBox/MailchimpFormWorkContainer';
import { useIsSmallScreen } from '../../hooks/useIsSmallScreen';
const SquareText = ({
    icon,
    title,
    desc,
}: {
    icon: string;
    title: string;
    desc: string;
}) => {
    return (
        <>
            <div className="square-box">
                <Stack spacing={'8px'} padding={'1.5rem'}>
                    <img
                        style={{ marginLeft: '-16px' }}
                        className="square-icon"
                        alt={'icon' + title}
                        src={icon}
                    />
                    <div className="square-title">{title}</div>
                    <div className="square-desc">{desc}</div>
                </Stack>
            </div>
        </>
    );
};

const WorkWithUsPage = (): JSX.Element => {
    const isSmallScreen = useIsSmallScreen();
    const squares = [
        {
            icon: icon1,
            title: 'Family Atmosphere',
            desc: 'Join a close-knit team that values collaboration and teamwork.',
        },
        {
            icon: icon2,
            title: 'Quality Ingredients',
            desc: 'Work with fresh, high-quality ingredients to create authentic Italian dishes.',
        },
        {
            icon: icon3,
            title: 'Training and Development',
            desc: 'Join a close-knit team that values collaboration and teamwork.',
        },
        {
            icon: icon4,
            title: 'Customer Satisfaction',
            desc: 'Be part of a team committed to delivering memorable dining experiences for our guest.',
        },
    ];
    return (
        <>
            <MailchimpFormWorkContainer />
            <div className="nd-title">Why work with us?</div>
            <Stack
                direction={isSmallScreen ? 'column' : 'row'}
                spacing={'1.5rem'}
            >
                {squares.map((e, x) => (
                    <SquareText
                        key={x}
                        icon={e.icon}
                        title={e.title}
                        desc={e.desc}
                    />
                ))}
            </Stack>
        </>
    );
};

export default WorkWithUsPage;
